<template>
  <div class="">
    <vue-data-table
      :cols="[
        {
          header: 'Name',
          value: 'name',
        },
        {
          header: 'Type',
          value: 'type'
        },
        {
          header: 'Added At',
          value: 'created_at',
          sort: true
        },
        {
          header: 'Last Modified',
          value: 'updated_at',
          sort: true
        },
        {
          header: 'Actions',
          value: 'actions'
        }
      ]"
      path="sets"
    >
      <template #header-left>
        <vue-button
          :icon="['fas', 'plus-circle']"
          @click="$router.push({ name: 'sets.new' })"
        >
          Create
        </vue-button>
      </template>
      <template v-slot:item.type="row">
        {{ row.type.replace('App\\Models\\', '') }}
      </template>
      <template v-slot:item.created_at="row">
        {{ $momentDateTimeHuman(row.created_at) }}
      </template>
      <template v-slot:item.updated_at="row">
        {{ $momentDateTimeHuman(row.updated_at) }}
      </template>
      <template v-slot:item.actions="row">
        <vue-link
          :to="{
              name: 'sets.single',
              params: {
                model: row.id
              }
          }"
          :icon="['fas', 'edit']"
          :aria-label="`Edit Set: ${row.name}`"
          text
          sm
          class="inline-flex cursor-pointer"
        />
      </template>
    </vue-data-table>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'Sets'
}
</script>
