var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('vue-data-table',{attrs:{"cols":[
      {
        header: 'Name',
        value: 'name',
      },
      {
        header: 'Type',
        value: 'type'
      },
      {
        header: 'Added At',
        value: 'created_at',
        sort: true
      },
      {
        header: 'Last Modified',
        value: 'updated_at',
        sort: true
      },
      {
        header: 'Actions',
        value: 'actions'
      }
    ],"path":"sets"},scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_c('vue-button',{attrs:{"icon":['fas', 'plus-circle']},on:{"click":function($event){return _vm.$router.push({ name: 'sets.new' })}}},[_vm._v(" Create ")])]},proxy:true},{key:"item.type",fn:function(row){return [_vm._v(" "+_vm._s(row.type.replace('App\\Models\\', ''))+" ")]}},{key:"item.created_at",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$momentDateTimeHuman(row.created_at))+" ")]}},{key:"item.updated_at",fn:function(row){return [_vm._v(" "+_vm._s(_vm.$momentDateTimeHuman(row.updated_at))+" ")]}},{key:"item.actions",fn:function(row){return [_c('vue-link',{staticClass:"inline-flex cursor-pointer",attrs:{"to":{
            name: 'sets.single',
            params: {
              model: row.id
            }
        },"icon":['fas', 'edit'],"aria-label":("Edit Set: " + (row.name)),"text":"","sm":""}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }